export default {
  translations: {
    navbar: {
      home: 'Página Inicial',
      about: 'Sobre',
      videos: 'Vídeos',
      songs: 'Músicas',
      contact: 'Contato'
    },
    home: {
      message: 'Olá Mundo'
    }
  }
}
