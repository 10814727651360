export default {
  translations: {
    navbar: {
      home: 'Home',
      about: 'About',
      videos: 'Videos',
      songs: 'Songs',
      contact: 'Contact'
    },
    home: {
      message: 'Olá Mundo'
    }
  }
}
