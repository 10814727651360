// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@presentation/assets/images/destaque-02.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#about{position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center/cover fixed}#about:before{content:'';background:rgba(0,0,0,0.7);position:absolute;top:0;left:0;right:0;bottom:0}#about>div{position:relative;z-index:2;max-width:940px;margin:auto}#about h3{font-size:2em;line-height:1.5}\n", "",{"version":3,"sources":["webpack://./src/presentation/components/Home/About/About.scss"],"names":[],"mappings":"AAAA,OACE,iBAAkB,CAClB,sFAAkG,CAFpG,cAKI,UAAW,CACX,0BAA6B,CAC7B,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,OAAQ,CACR,QAAS,CAXb,WAeI,iBAAkB,CAClB,SAAU,CACV,eAAgB,CAChB,WAAY,CAlBhB,UAsBI,aAAc,CACd,eAAgB","sourcesContent":["#about {\n  position: relative;\n  background: url('@presentation/assets/images/destaque-02.jpg') no-repeat center center/cover fixed;\n  \n  &:before {\n    content: '';\n    background: rgba(0, 0, 0, .7);\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n  }\n\n  > div {\n    position: relative;\n    z-index: 2;\n    max-width: 940px;\n    margin: auto;\n  }\n\n  h3 {\n    font-size: 2em;\n    line-height: 1.5;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
